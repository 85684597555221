<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة إداري جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput description="" label="رقم الموبايل" horizontal autocomplete="phone" v-model.trim="form.phone"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.phone.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput type="password" invalid description="" label="كلمة المرور" horizontal autocomplete="password"
                  v-model.trim="form.password" invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.password.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="6">
                <CInput type="password" invalid description="" label="تأكيد كلمة المرور" horizontal
                  autocomplete="password" v-model.trim="form.password_confirmation"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.password_confirmation.$anyError" />
              </CCol>
            </CRow>


            <CRow class="mt-5">
              <CCol sm="12" md="6">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3">الصلاحيات</label>
                  <CCol sm="9">
                    <v-select :multiple="true" :options="roles" v-model="form.roles" :reduce="(role) => role.id" dir="rtl"
                      label="name" placeholder="اختار الصلاحيات">
                      <template> عذراً هذه الصلاحيات غير موجود </template>
                      <template v-if="$v.form.roles.$anyError" #footer>
                        <div style="opacity: 0.8" class="text-danger mt-2">
                          يجب عليك اختيار الصلاحيات
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
                <CCol sm="12" md="9">
                  <div role="group" class="form-group form-row">
                    <label class="col-form-label col-sm-3"> الحالة </label>
                    <CCol sm="9">
                      <div role="group" class="form-check form-check-inline">
                        <label>
                          <input type="radio" name="category_id" class="form-check-input" value="1" v-model="form.status" />
                          نشط
                        </label>
                      </div>

                      <div role="group" class="form-check form-check-inline">
                        <label>
                          <input type="radio" name="category_id" class="form-check-input" value="0" v-model="form.status" />
                          متوقف
                        </label>
                      </div>
                    </CCol>
                  </div>
                </CCol>
              </CRow>

          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton :disabled="loading" @click="submit" type="button" color="success">
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else>
              <CIcon name="cil-save" /> حفظ
            </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      roles: [],
      form: {
        name: "",
        phone: "",
        password: "",
        password_confirmation: "",
        status: 1,
        roles: [],
      },
      formErrors: null,
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2) },
      phone: { required },
      password: { required, min: minLength(6) },
      password_confirmation: { required, min: minLength(6) },
      roles: { required },
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;
      this.$http
        .post(`/admins/create`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "Admins" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
          this.loading = false;
        });
    },

    fetchRoles() {
      this.$http.get(`/roles`).then((res) => {
        this.roles = res.data.data;
      });
    },
  },
};
</script>
